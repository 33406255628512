import { StyleSheet, Dimensions, Platform } from 'react-native';

const { height, width } = Dimensions.get('window');
const aspectRatio = 9 / 16;

export const colors = {
  primary: '#1C2233',
  secondary: '#ff6347',
  accent: '#4CAF50',
  background: '#f5f5f5',
  text: '#333333',
};

export default StyleSheet.create({
  // Styles de base
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  stickyHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: colors.primary,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  // Styles de scroll
  scrollContainer: {
    paddingTop: 70,
    paddingHorizontal: 15,
  },
  scrollView: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && {
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
    }),
  },

  // Styles de formulaire
  input: {
    height: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginBottom: 15,
    backgroundColor: '#f2f2f2',
    fontSize: 16,
    color: colors.text,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginVertical: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },

  // Styles de bouton
  button: {
    backgroundColor: colors.secondary,
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 15,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    textAlign: 'center', 
    fontWeight: '600',
  },
  installButton: {
    backgroundColor: colors.accent,
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  installButtonText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
  },

  // Styles de la TabBar
  tabBarContainer: {
    flexDirection: 'row',
    height: 85,
    backgroundColor: colors.primary,
    position: 'relative',
    paddingVertical: 10,
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    height: '100%',
    minWidth: 80,
  },
  activeTab: {
    transform: [{ translateY: -5 }],
  },
  slider: {
    position: 'absolute',
    top: 3, 
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderContent: {
    width: '65%',
    height: '85%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 20,
    boxShadow: `
      inset 0px 2px 4px rgba(255, 255, 255, 0.2),
      inset 0px -2px 4px rgba(0, 0, 0, 0.2),
      0px 4px 8px rgba(0, 0, 0, 0.25)
    `,
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  icon: {
    marginBottom: 6,
  },
  tabLabel: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
    opacity: 1,
    fontWeight: 'bold',
  },
  activeTabLabel: {
    color: colors.secondary,
    opacity: 1,
  }
});