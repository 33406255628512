import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabBar from './components/TabBar';

// Import des écrans
import HomeScreen from './screens/HomeScreen';
import ReservationScreen from './screens/ReservationScreen';
import AuthScreen from './screens/AuthScreen';

const Tab = createBottomTabNavigator();

const App = () => {
  return (
    <NavigationContainer>
      <Tab.Navigator
        tabBar={props => <TabBar {...props} />}
        screenOptions={{
          headerShown: false,
        }}
      >
        <Tab.Screen name="Accueil" component={HomeScreen} />
        <Tab.Screen name="Réservation" component={ReservationScreen} />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default App;