import { StyleSheet, Dimensions } from 'react-native';
import { colors } from './GlobalStyles';

const { height } = Dimensions.get('window');
const aspectRatio = 9 / 16;

export default StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    color: colors.text,
  },
  instructions: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    color: colors.text,
  },
  iframeContainer: {
    flex: 1,
    width: '100%',
    height: height * 0.7,
    aspectRatio: aspectRatio,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
  },
  webview: {
    flex: 1,
    width: '100%',
    height: '100%',
  }
});