// Import the functions you need from the Firebase SDK
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInAnonymously,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  PhoneAuthProvider,
  signInWithCredential
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Votre configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA-eRHbO5WbegU7-W1AmlMdMQ_udfQG-sw",
  authDomain: "lmplanner-429000.firebaseapp.com",
  projectId: "lmplanner-429000",
  storageBucket: "lmplanner-429000.appspot.com",
  messagingSenderId: "552996729506",
  appId: "1:552996729506:web:daf1ed24220126c0531dfb",
  measurementId: "G-D0KLJ1YVN0"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firebase Auth et Firestore
const auth = getAuth(app);
const db = getFirestore(app);

// Service d'authentification
const authService = {
  // Connexion anonyme
  signInAnonymously: async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      return { success: true, user: userCredential.user };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Inscription par email/mot de passe
  registerWithEmail: async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return { success: true, user: userCredential.user };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Connexion par email/mot de passe
  loginWithEmail: async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return { success: true, user: userCredential.user };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Connexion par téléphone - Envoi du code
  sendPhoneVerification: async (phoneNumber, recaptchaVerifier) => {
    try {
      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(
        phoneNumber,
        recaptchaVerifier
      );
      return { success: true, verificationId };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Connexion par téléphone - Vérification du code
  verifyPhoneCode: async (verificationId, code) => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, code);
      const userCredential = await signInWithCredential(auth, credential);
      return { success: true, user: userCredential.user };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Déconnexion
  signOut: async () => {
    try {
      await signOut(auth);
      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error.message,
        errorCode: error.code 
      };
    }
  },

  // Obtenir l'utilisateur actuel
  getCurrentUser: () => {
    return auth.currentUser;
  }
};

export { auth, db, authService };