import React, { useRef, useEffect } from 'react';
import { View, Animated, Pressable, useWindowDimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import globalStyles, { colors } from '../styles/GlobalStyles';

const TabBar = ({ state, descriptors, navigation }) => {
  const slideAnim = useRef(new Animated.Value(0)).current;
  const { width: windowWidth } = useWindowDimensions();
  const tabWidth = windowWidth / state.routes.length;

  useEffect(() => {
    Animated.spring(slideAnim, {
      toValue: state.index,
      friction: 8,
      tension: 50,
      useNativeDriver: true,
    }).start();
  }, [state.index]);

  const translateX = slideAnim.interpolate({
    inputRange: [0, 1, 2],
    outputRange: [0, tabWidth, tabWidth * 2],
  });

  return (
    <View style={globalStyles.tabBarContainer}>
      <Animated.View 
        style={[
          globalStyles.slider,
          {
            transform: [{ translateX }],
            width: tabWidth,
          }
        ]}
      >
        <View style={globalStyles.sliderContent} />
      </Animated.View>

      {state.routes.map((route, index) => {
        const isFocused = state.index === index;
        const onPress = () => {
          if (!isFocused) {
            navigation.navigate(route.name);
          }
        };

        const iconName = 
          route.name === 'Accueil' ? 'home' :
          route.name === 'Réservation' ? 'calendar' : 'person';

        return (
          <Pressable 
            key={route.key} 
            style={[
              globalStyles.tab,
              isFocused && globalStyles.activeTab
            ]}
            onPress={onPress}
            android_ripple={{
              color: 'rgba(255, 255, 255, 0.2)',
              borderless: false,
            }}
          >
            <Ionicons
              name={iconName}
              size={24}
              color={isFocused ? colors.secondary : 'rgba(255, 255, 255, 0.5)'}
              style={globalStyles.icon}
            />
            <Animated.Text
              style={[
                globalStyles.tabLabel,
                isFocused && globalStyles.activeTabLabel
              ]}
            >
              {route.name}
            </Animated.Text>
          </Pressable>
        );
      })}
    </View>
  );
};

export default TabBar;