import React from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import globalStyles from '../styles/GlobalStyles';
import styles from '../styles/HomeStyles';

const HomeScreen = ({ navigation }) => {
  return (
    <View style={globalStyles.container}>
      <View style={globalStyles.stickyHeader}>
        <Text style={globalStyles.headerText}>💈 LmPlanner 💈</Text>
      </View>
      <ScrollView 
        contentContainerStyle={[globalStyles.scrollContainer, { paddingBottom: 150 }]} 
        style={globalStyles.scrollView}
        showsVerticalScrollIndicator={true}
      >
        <View style={{ alignItems: 'center', marginVertical: -20 }}> {/* Centré sans la vidéo */}
          <View style={{ width: '80%', alignItems: 'center' }}>
            <Text style={styles.centeredText}>
              <Text style={{ fontWeight: 'bold' }}>Réserve ton rendez-vous en un clin d'œil ! </Text> ⚡
            </Text>
            <Text style={styles.descriptionText}>
              <Text style={{ fontWeight: 'bold' }}>Trouve le créneau idéal pour toi.</Text> 🗓️
            </Text>
            <Text style={styles.descriptionText}>
              <Text style={{ fontWeight: 'bold' }}>Confirme ta réservation et reçois ta confirmation par mail.</Text> ✅
            </Text>
            <Text style={styles.centeredText}>
              <Text style={{ fontWeight: 'bold' }}>Bientôt disponible :</Text> 🚀
            </Text>
            <Text style={styles.descriptionText}>
              - <Text style={{ fontWeight: 'bold' }}>Historique de tes rendez-vous avec photos/vidéos de la prestation.</Text> 📸
            </Text>
            <Text style={styles.descriptionText}>
              - <Text style={{ fontWeight: 'bold' }}>Paiement sécurisé en ligne.</Text> 🔒
            </Text>
            <Text style={styles.descriptionText}>
              - <Text style={{ fontWeight: 'bold' }}>Programme de fidélité avantageux.</Text> 🎉
            </Text>
            <Text style={styles.descriptionText}>
              - <Text style={{ fontWeight: 'bold' }}>Compte personnel pour un suivi personnalisé et des réservations encore plus rapides.</Text> 👤
            </Text>
          </View>
        </View>
      </ScrollView>

      {/* Boutons fixes en bas de la page */}
      <View style={styles.fixedButtonContainer}>
        <TouchableOpacity style={globalStyles.button} onPress={() => navigation.navigate('Réservation')}>
          <Text style={globalStyles.buttonText}>Réserver maintenant !</Text>
        </TouchableOpacity>
        
        <TouchableOpacity 
          style={styles.instagramButton}
          onPress={() => Linking.openURL('https://www.instagram.com/7lm.barber/')}
        >
          <Ionicons name="logo-instagram" size={24} color="#fff" />
          <Text style={styles.instagramButtonText}>@7Lm.Barber</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default HomeScreen;