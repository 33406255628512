import React from 'react';
import { View, Text, ScrollView, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import globalStyles from '../styles/GlobalStyles';
import styles from '../styles/ReservationStyles';

const ReservationScreen = () => {
  const isWeb = Platform.OS === 'web';

  return (
    <View style={globalStyles.container}>
      <View style={globalStyles.stickyHeader}>
        <Text style={globalStyles.headerText}>🗓️ Réservation en ligne 🗓️</Text>
      </View>
      <ScrollView 
        contentContainerStyle={globalStyles.scrollContainer} 
        style={globalStyles.scrollView}
      >
        <View style={styles.iframeContainer}>
          {isWeb ? (
            <iframe
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1_ais8IOCaO4WOKXZ2CbwgVVF7Ois1Yqrs9k5IZxAR3VhsvQd2xrP46SIEcWheUM2yN6-ndNVI?gv=true"
              style={styles.iframe}
              title="Réservation"
            />
          ) : (
            <WebView
              source={{ uri: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1_ais8IOCaO4WOKXZ2CbwgVVF7Ois1Yqrs9k5IZxAR3VhsvQd2xrP46SIEcWheUM2yN6-ndNVI?gv=true' }}
              style={styles.webview}
              javaScriptEnabled={true}
              injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('name', 'viewport'); meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'); document.getElementsByTagName('head')[0].appendChild(meta);`}
              scalesPageToFit={false}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default ReservationScreen;