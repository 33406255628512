import { StyleSheet, Dimensions } from 'react-native';

const { height } = Dimensions.get('window');
const aspectRatio = 9 / 16;

export default StyleSheet.create({
  centeredText: {
    textAlign: 'center',
    fontSize: 16,
    marginTop: 25,
    marginBottom: 25,
    color: '#333',
    fontWeight: 'bold',
  },
  welcomeText: {
    fontSize: 24,
    marginBottom: 50,
  },
  descriptionText: {
    fontSize: 14,
    color: '#666',
    marginVertical: 10,
    lineHeight: 22,
    fontWeight: 'bold',
  },
  videoContainer: {
    justifyContent: 'center',
    aspectRatio: 9 / 16,  // Pour correspondre au ratio 9:16 sans bordures
    height: height * 0.35,
    marginVertical: 0,
    overflow: 'hidden',
  },
  
  fixedButtonContainer: {
    position: 'absolute',
    bottom: 10,  // Ajuste pour rapprocher les boutons de la navbar
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
  featureList: {
    marginBottom: 20,
  },
  ctaSection: {
    marginTop: 20,
    marginBottom: 30,
    alignItems: 'center',
  },
  buttonsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 100,
  },
  buttonSpacing: {
    marginVertical: 10,
  },
  instagramButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#C13584',
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    width: '100%', // Définit le bouton pour utiliser toute la largeur disponible
  justifyContent: 'center', // Centre le contenu horizontalement
  },
  instagramButtonText: {
    color: '#fff',
    marginLeft: 10,
    fontWeight: 'bold',
  },
});